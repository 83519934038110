<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="组局号：">
        <el-input v-model="searchForm.zuju_id" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="日期范围：">
        <el-date-picker
          v-model="searchForm.create_time"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="支付状态：">
        <el-select v-model="searchForm.pay_status" placeholder="请选择" size="mini">
          <el-option v-for="item in payStatusTypeList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态：">
        <el-select v-model="searchForm.order_status" placeholder="请选择" size="mini">
          <el-option v-for="item in orderStatusTypeList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getDataInfo(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格列表 -->
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center">
        <template #header>
          <!-- <el-checkbox></el-checkbox> -->
          编号
        </template>
        <template v-slot="{ row }">
          {{ row.id }}
        </template>
      </el-table-column>
      <el-table-column prop="ordersn" label="订单号" align="center"></el-table-column>
      <el-table-column prop="trade_no" label="第三方订单号" align="center"></el-table-column>
      <el-table-column label="组局号" align="center">
        <template v-slot="{ row }">
          {{ row.zuju_info ? row.zuju_info.id : '已删除' }}
        </template>
      </el-table-column>
      <el-table-column label="组局标题" align="center">
        <template v-slot="{ row }">
          {{ row.zuju_info ? row.zuju_info.zuju_title : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="组局用户" align="center" width="200px">
        <template v-slot="{ row }">
          <UserInfo :avatar="row.avatar" :name="row.nick_name"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column label="人均金额" align="center">
        <template v-slot="{ row }">
          {{ row.zuju_info ? row.zuju_info.avg_money : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="订单已支付金额" align="center">
        <template v-slot="{ row }">
          {{ row.price }}
        </template>
      </el-table-column>
      <el-table-column label="订单类型" align="center">
        <template v-slot="{ row }">{{ row.zuju_info ? getOrderType(row.zuju_info.avg_type) : '--' }}</template>
      </el-table-column>
      <el-table-column label="订单状态" align="center">
        <template v-slot="{ row }">{{ row.zuju_info ? getOrderStatus(row.zuju_info.status) : '--' }}</template>
      </el-table-column>
      <el-table-column label="支付状态" align="center">
        <template v-slot="{ row }">{{ getPayStatus(row.status) }}</template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="200px">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <!-- <el-table-column label="所属店铺" align="center" width="200px">
        <template v-slot="{ row }">
      <UserInfo :avatar="row.shop_info.store_logo" :name="row.shop_info.store_name"></UserInfo> 
        </template> 
      </el-table-column>-->
      <!-- <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="row.zuju_info.zuju_type == 1 && row.order_status == 2" type="primary" size="small" @click="openWriteOff(row)">核销</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <Paging :total="searchForm.total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 核销弹窗 -->
    <el-dialog title="核销弹窗" :visible.sync="showWriteOff" width="500px" @closed="closedCode">
      <el-form label-width="80px">
        <el-form-item label="订单号：">{{ writeOffInfo.pay_sn }}</el-form-item>
        <el-form-item label="核销码：">
          <el-input placeholder="请输入核销码" style="width: 200px" v-model="writeOffCode"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closedCode">取 消</el-button>
        <el-button type="primary" @click="sureWriteOff">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
export default {
  components: {
    UserInfo,
    Paging,
  },
  data() {
    return {
      getDateformat,
      searchForm: {
        page: 1,
        rows: 10,
        total_number: 0,
        create_time: [],
        zuju_id: '',
        order_status: '',
        pay_status: '',
      },
      dataList: [],
      payStatusTypeList: [
        { status: -1, name: '订单取消' },
        { status: 0, name: '待付款' },
        { status: 1, name: '已支付' },
      ],
      orderStatusTypeList: [
        { status: -1, name: '订单取消' },
        { status: 0, name: '待付款' },
        { status: 1, name: '组局中' },
        { status: 2, name: '组局成功' },
        { status: 3, name: '已核销' },
        { status: 4, name: '已退款' },
      ],
      avg_state: [
        { id: 0, name: '单购套餐' },
        { id: 1, name: '男女AA' },
        { id: 2, name: '男A女免' },
        { id: 3, name: '我请客(红包局)' },
        { id: 4, name: '我请客(商务局)' },
        { id: 5, name: '我请客 限女生' },
      ],
      // 当前核销订单
      writeOffInfo: {},
      showWriteOff: false,
      writeOffCode: '',
    };
  },
  created() {
    this.getDataInfo();
  },
  methods: {
    closedCode() {
      this.showWriteOff = false;
      this.writeOffInfo = {};
      this.writeOffCode = '';
    },
    openWriteOff(data) {
      this.writeOffInfo = data;
      this.showWriteOff = true;
      this.writeOffCode = data.verification_info.verification_no;
    },
    sureWriteOff() {
      this.$axios
        .post(this.$api.yiyun.verificationUpdate, {
          verification_no: this.writeOffCode,
        })
        .then(res => {
          if (res.code == 0) {
            this.closedCode();
            this.$message.success('核销成功');
            this.getDataInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      // 重新获取分页数据
      this.getDataInfo();
    },
    getOrderType(status) {
      if (status === undefined) return '未知';
      return this.avg_state.find(i => i.id == status).name;
    },
    getOrderStatus(status) {
      if (status === undefined) return '未知';
      return this.orderStatusTypeList.find(i => i.status == status).name;
    },
    getPayStatus(status) {
      if (status === undefined) return '未知';
      return this.payStatusTypeList.find(i => i.status == status).name;
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        total_number: 0,
        create_time: [],
        keywords_type: '',
        keywords: '',
        zuju_id: '',
        pay_status: '',
        order_status: '',
      };
      this.getDataInfo();
    },
    //
    getDataInfo(style) {
      if (style) {
        this.searchForm.page = 1;
      }
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.zuju_id) obj.zuju_id = this.searchForm.zuju_id;
      if (this.searchForm.pay_status) obj.pay_status = this.searchForm.pay_status;
      if (this.searchForm.order_status) obj.order_status = this.searchForm.order_status;
      if (this.searchForm.create_time?.length) {
        obj.create_time = [];
        obj.create_time[0] = Math.ceil(this.searchForm.create_time[0].getTime() / 1000);
        obj.create_time[1] = Math.ceil(this.searchForm.create_time[1].getTime() / 1000);
        if (obj.create_time[0] == obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.yiyun.getActivityOrder, obj).then(res => {
        if (res.code == 0) {
          this.dataList = res.result.list;
          this.searchForm.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
