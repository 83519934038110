var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"110px"}},[_c('el-form-item',{attrs:{"label":"组局号："}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.searchForm.zuju_id),callback:function ($$v) {_vm.$set(_vm.searchForm, "zuju_id", $$v)},expression:"searchForm.zuju_id"}})],1),_c('el-form-item',{attrs:{"label":"日期范围："}},[_c('el-date-picker',{attrs:{"type":"datetimerange","picker-options":_vm.pickerOptions,"range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","align":"right","size":"mini"},model:{value:(_vm.searchForm.create_time),callback:function ($$v) {_vm.$set(_vm.searchForm, "create_time", $$v)},expression:"searchForm.create_time"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getDataInfo(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 编号 ")]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"ordersn","label":"订单号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"trade_no","label":"第三方订单号","align":"center"}}),_c('el-table-column',{attrs:{"label":"组局号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.zuju_info ? row.zuju_info.id : '已删除')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"组局标题","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.zuju_info ? row.zuju_info.zuju_title : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"组局用户","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('UserInfo',{attrs:{"avatar":row.avatar,"name":row.nick_name}})]}}])}),_c('el-table-column',{attrs:{"label":"人均金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.zuju_info ? row.zuju_info.avg_money : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单已支付金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.price)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.zuju_info ? _vm.getOrderType(row.zuju_info.avg_type) : '--'))]}}])}),_c('el-table-column',{attrs:{"label":"订单状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.zuju_info ? _vm.getOrderStatus(row.zuju_info.status) : '--'))]}}])}),_c('el-table-column',{attrs:{"label":"支付状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getPayStatus(row.status)))]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getDateformat(row.create_time)))]}}])})],2),_c('Paging',{attrs:{"total":_vm.searchForm.total_number,"page":_vm.searchForm.page,"pageNum":_vm.searchForm.rows},on:{"updatePageNum":_vm.updateData}}),_c('el-dialog',{attrs:{"title":"核销弹窗","visible":_vm.showWriteOff,"width":"500px"},on:{"update:visible":function($event){_vm.showWriteOff=$event},"closed":_vm.closedCode}},[_c('el-form',{attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"订单号："}},[_vm._v(_vm._s(_vm.writeOffInfo.pay_sn))]),_c('el-form-item',{attrs:{"label":"核销码："}},[_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入核销码"},model:{value:(_vm.writeOffCode),callback:function ($$v) {_vm.writeOffCode=$$v},expression:"writeOffCode"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closedCode}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sureWriteOff}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }